/**
 * Inhaltsverzeichnis
 * 	1. Menu
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */


// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import IconElement from '../icon/class';
import { CSSRuleSet } from '../../ts/class/style/exportlist';
import style from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. Class
// --------------------------------------------------
class SearchbarElement	extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#
	/** Observed Attributes */
	static observedAttributes: string[]	=	[
		'state', 'placeholder', 'label', 'value', 'action', 'button'
	];


		// #==== Initialised ====#
	/** Shadow DOM */
	public shadowRoot: ShadowRoot		=	this.attachShadow({mode: 'open'});
	/**	Stylesheet */
	private stylesheet: CSSRuleSet		=	new CSSRuleSet(style);
	/** List of Sub Elements */
	private subElements					=	{
		input:	document.createElement('input'),
		button:	document.createElement('button'),
		span:	document.createElement('span'),
		icon:	document.createElement('cvh-icon') as IconElement,
		wrap:	document.createElement('div'),
		form:	document.createElement('form')
	};
	/** Icon States */
	private iconStates					=	{
		open: 'f002',
		close: 'f00d',
	};


		// #==== Uninitialised ====#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor() {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadowRoot.adoptedStyleSheets	=	[this.stylesheet.getStylesheet()];


		// #==== Mehtods ====#
		this.build();
	}


	// #=#=#=#=#=# 1.? Build Shadow DOM #=#=#=#=#=#
	/**
	 * Builds the Shadow DOM
	 */
	private build(
	): void {
		// #==== Prepare Elements ====#
		// +---- Form ----+
		this.subElements.wrap.setAttribute('part', 'form');
		this.subElements.form.setAttribute('method', 'GET');
		this.subElements.form.setAttribute('role', 'search');

		// +---- Input ----+
		this.subElements.input.setAttribute('type', 'search');
		this.subElements.input.setAttribute('aria-label', 'Enter a search term');
		this.subElements.input.setAttribute('name', 's');

		// +---- Icon ----+
		this.subElements.icon.setAttribute('character', this.iconStates.open);

		// +---- Button ----+
		this.subElements.button.setAttribute('part', 'button');
		this.subElements.span.setAttribute('part', 'button-label');


		// #==== Connect Elements ====#
		this.subElements.button.appendChild(this.subElements.icon);
		this.subElements.button.appendChild(this.subElements.span);
		this.shadowRoot.appendChild(this.subElements.button);
		this.subElements.form.appendChild(this.subElements.input);
		this.subElements.wrap.appendChild(this.subElements.form);
		this.shadowRoot.appendChild(this.subElements.wrap);
	}


	// #=#=#=#=#=# 1.? Attributes Changed #=#=#=#=#=#
	/**
	 * Lifecycle Method: Attributes Changed
	 */
	attributeChangedCallback(
		name: string,
		oldValue: string,
		newValue: string
	): void {
		// #==== Guard ====#
		// Bail if no change was made
		if(oldValue === newValue) {
			return;
		}


		// #==== Set Attribute ====#
		switch(name) {
			case 'state':
				switch(newValue) {
					case 'open':
						this.open();
						return;

					case 'closed':
						this.close();
						return;

					case 'static':
						this.subElements.icon.setAttribute('character', this.iconStates.open);
						return;

					default:
						return;
				}

			case 'button':
				this.subElements.span.innerText	=	newValue;
				break;

			case 'placeholder':
				this.subElements.input.setAttribute('placeholder', newValue);
				break;

			case 'label':
				this.subElements.input.setAttribute('aria-label', newValue);
				break;

			case 'value':
				this.subElements.input.setAttribute('value', newValue);
				break;

			case 'action':
				this.subElements.form.setAttribute('action', newValue);
				break;
		}
	}


	// #=#=#=#=#=# 1.? Connected Callback #=#=#=#=#=#
	/**
	 * Lifecycle Method: Connected Callback
	 */
	connectedCallback(
	): void {
		// #==== Set Element State ====#
		if(!this.hasAttribute('state')) {
			this.setAttribute('state', 'static');
		}


		// #==== Events ====#
		this.connectEvents();
	}


	// #=#=#=#=#=# 1.? Connect Events #=#=#=#=#=#
	/**
	 * Handles the Eventlisteners for all sub elements
	 */
	private connectEvents(
	): void {
		// #==== Button ====#
		this.subElements.button.addEventListener('click', event => {
			event.preventDefault();

			if(this.getAttribute('state') !== 'static') {
				this.toggle();
			}

			else {
				this.submit();
			}
		});


		// #==== Input ====#
		this.subElements.form.addEventListener('submit', event => {
			event.preventDefault();
			this.submit();
		});
	}


	// #=#=#=#=#=# 1.? Toggle #=#=#=#=#=#
	/**
	 * Toggles the open/close state of the Searchbar
	 */
	public toggle(
	): void {
		// #==== Get State ====#
		const state	=	this.getAttribute('state') as string;


		// #==== Toggle State ====#
		switch(state) {
			case 'open':
				this.close();
				break;

			case 'closed':
				this.open();
				break;

			default:
				console.warn(`Searchbar: Unknown State "${state}"`);
		}
	}


	// #=#=#=#=#=# 1.? Open #=#=#=#=#=#
	/**
	 * Open	the Searchbar
	 */
	public open(
	): void {
		// #==== Set State ====#
		SearchbarElement.observedAttributes
		this.setAttribute('state', 'open');


		// #==== Form Accessibilty ====#
		this.subElements.form.setAttribute('aria-expanded', 'true');
		this.subElements.form.removeAttribute('aria-hidden');
		this.subElements.input.removeAttribute('tab-index');


		// #==== Set Icon ====#
		this.subElements.icon.setAttribute('character', this.iconStates.close);
	}


	// #=#=#=#=#=# 1.? Close #=#=#=#=#=#
	/**
	 * Close the Searchbar
	 */
	public close(
	): void {
		// #==== Set State ====#
		this.setAttribute('state', 'closed');


		// #==== Form Accessibilty ====#
		this.subElements.form.setAttribute('aria-expanded', 'false');
		this.subElements.form.setAttribute('aria-hidden', '');
		this.subElements.input.setAttribute('tab-index', '-1');


		// #==== Set Icon ====#
		this.subElements.icon.setAttribute('character', this.iconStates.open);
	}


	// #=#=#=#=#=# 1.? Submit #=#=#=#=#=#
	/**
	 * Submits the Searchbar
	 */
	public submit(
	): void {
		if(this.subElements.input.value === '') {
			this.subElements.input.focus();
		}

		else {
			this.subElements.form.submit();
		}
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-searchbar', SearchbarElement);