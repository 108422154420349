/**
 * Inhaltsverzeichnis
 * 	0. Setup
 * 	1. Section Inner
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 Build
 * 		1.4 Element Connected
 * 
 */


/* ================================================== */
/* 0. Setup */
/* -------------------------------------------------- */
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { CSSRuleSet } from '../../ts/class/style/exportlist';
import { debounce } from '../../ts/module/timing';
import styles from './style.scss?inline';


// ==================================================
// MARK: 1. MenuElement
// --------------------------------------------------
export default class IconElement extends HTMLElement {
	// #=#=#=#=#=# 2.1 Properties #=#=#=#=#=#
		// #==== Uninitialized ====#
	/**	Character used as the Icon for Gylphs for Unicodes */
	private character?: string;
	/**	Determins the Type of the character input | Can be unicode, glyph or svg */
	private type?: string;
	/**	Font to display the unicode / glyph character */
	private font?: string;
	

		// #==== Initialized ====#
	/**	Shadow DOM of the Element */
	public shadowRoot			=	this.attachShadow({mode: 'open'});
	/**	Slot Element for SVG Support */
	private svgSlot				=	this.shadowRoot.querySelector('slot');
	/**	Internal Stylesheet for passing CSS Variables */
	private stylesheet			=	new CSSRuleSet(styles);
	/**	Debounced Update Function */
	private update				=	debounce(this.__update, 50);


		// #==== Static ====#
	static	observedAttributes	=	["character", "type", "font"];


	// #=#=#=#=#=# 2.2 Constructor #=#=#=#=#=#
	constructor() {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadowRoot.adoptedStyleSheets		=	[this.stylesheet.getStylesheet()];


		// #==== Build ====#
		this.render();
	}


	// #=#=#=#=# 2.3 Build #=#=#=#=#=#
	/**
	 * Builds the ShadowRoot HTML of the element.
	 */
	private render(
	): void {
		this.shadowRoot.innerHTML = `
			<div part="inner">
				<slot></slot>
			</div>
		`;
	}


	// #=#=#=#=#=# 2.4 Element Connected #=#=#=#=#=#
	/**
	 * Is called when the element is connected to the DOM.
	 * @access	public
	 * @return	{void}
	 */
	public connectedCallback(
	): void {
		this.update();
	}


	// #=#=#=#=#=# 2.5 Attribute Changed #=#=#=#=#=#
	public attributeChangedCallback(
	): void {
		this.update();
	}


	// #=#=#=#=#=# 2.6 Update #=#=#=#=#=#
	/**
	 * Update the Values passed to CSS of the Element.
	 */
	private __update(
	): void {
		// #==== Update Attributes ====#
		this.getAttributeData();


		// #==== Update CSS ====#
		this.writeVariables();
	}


	// #=#=#=#=#=# 2.5 Get Attribute Data #=#=#=#=#=#
	/**
	 * Retrieves and stores data from the Element attributes.
	 */
	private getAttributeData(
	): void {
		// Retrieve
		this.getCharacter();
		this.getType();
		this.getFont();

		// Validate
		this.validateCharacter();
		this.validateType();
		this.validateFont();
	}


	// #=#=#=#=#=# 2.5.a Get Character #=#=#=#=#=#
	/**
	 * Stores the Unicode / Glyph Character from the Element
	 */
	private getCharacter(
	): void {
		this.character		=	this.attributes.getNamedItem('character')?.value;
	}


	// #=#=#=#=#=# 2.5.b Get Type #=#=#=#=#=#
	/**
	 * Stores the Type of the Character
	 */
	private getType(
	): void {
		// #==== Get Value of Attribute ====#
		const value		=	this.attributes.getNamedItem('type')?.value


		// #==== Determine Type ====#
		if(!value) {
			// SVG
			if(!this.hasAttribute('character') && this.children.length > 0) {
				this.type		=	'svg';
				return;
			}

			// Unicode / Glyph
			this.type		=	this.character?.length === 1? 'glyph' : 'unicode';
		}

		else {
			this.type		=	value;
		}
	}


	// #=#=#=#=#=# 2.5.c Get Font #=#=#=#=#=#
	/**
	 * Stores the Font of the Character
	 */
	getFont(
	): void {
		this.font		=	this.attributes.getNamedItem('font')?.value ?? 'fas'
	}


	// #=#=#=#=#=# 2.6 Validate Character #=#=#=#=#=#
	/**
	 * Validates the Character based on the Type
	 */
	private validateType(
	): void {
		if(this.children.length > 0) {
			this.type		=	'svg';
		}
	}


	// #=#=#=#=#=# 2.? Validate Character #=#=#=#=#=#
	private validateCharacter(
	): void {
		// #==== Guard ====#
		if(!this.character) {
			return;
		}


		// #==== Sanitise ====#
		// Unicode
		if(this.type === 'unicode' && !this.character.startsWith('\\')) {
			this.character		=	`\\${this.character}`;
		}

		// Glyph
		else if(this.type === 'glyph' && this.character.startsWith('\\')) {
			this.character		=	this.character.slice(1);
		}


		// #==== Correct Quatations ====#
		switch(this.type) {
			case 'unicode':
			case 'glyph':
				this.character		=	`"${this.character}"`;
				break;
		}
	}


	// #=#=#=#=#=# 2.? Validate Character #=#=#=#=#=#
	/**
	 * Validates the Font of the Character
	 */
	private validateFont() {
		// #==== Font Presets ====#
		switch(this.font) {
			case 'fas':
				this.font		=	'var(--fa-font-solid)';
				return;
			case 'far':
				this.font		=	'var(--fa-font-regular)';
				return;
			case 'fab':
				this.font		=	'var(--fa-font-brands)';
				return
		}

		
		// #==== CSS Variable Shorthand ====#
		if(this.font && this.font.startsWith('--')) {
			this.font		=	`var(${this.font})`;
			return;
		}
	}


	// #=#=#=#=#=# 2.? Write to CSS #=#=#=#=#=#
	/**
	 * Passes values to CSS Variables
	 */
	private writeVariables() {
		// #==== Remove Lines ====#
		if(this.type === 'svg') {
			this.stylesheet.removeProperty(':host', '--_icon-character');
			this.stylesheet.removeProperty(':host', '--_icon-font');
			return;
		}


		// #==== Write Lines ====#
		if(this.character)
			this.stylesheet.setProperty(':host', '--_icon-character', this.character);
		if(this.font)
			this.stylesheet.setProperty(':host', '--_icon-font', this.font);
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-icon', IconElement);