/**
 * Setup File for Theme Scripts
 * Executes early in the Header, before the DOM is fully loaded
 * 
 * Inhaltsverzeichnis
 * 	1. 
 * 
 */


// ==================================================
// MARK: 0. Imports
// --------------------------------------------------
import './asset/prototype';
import './asset/component-regstry';
import { SurfaceObserver } from './class/observer/exportlist';


// ==================================================
// MARK: 1. 
// --------------------------------------------------
new SurfaceObserver(document.documentElement, {
	name: 'window',
	height: true,
	width: true,
	initial: false,
	pixel: false
})