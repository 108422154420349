/**
 * Inhaltsverzeichnis
 * 	1. Menu
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */


// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { BreakpointObserver } from '../../ts/class/observer/exportlist';
import { CSSRuleSet } from '../../ts/class/style/exportlist';
import style from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. Class
// --------------------------------------------------
class MenuElement	extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Initialised ====#
	/** Shadow DOM */
	private shadow: ShadowRoot	=	this.attachShadow({mode: 'open'});
	/**	Stylesheet */
	private stylesheet: CSSRuleSet	=	new CSSRuleSet(style);


		// #==== Uninitialised ====#
	/**	Dialog Element */
	private subelements: {
		button: HTMLButtonElement,
		dialog: HTMLDialogElement
	};


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor() {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadow.adoptedStyleSheets	=	[this.stylesheet.getStylesheet()];


		// #==== Mehtods ====#
		this.build();
	}


	// #=#=#=#=#=# 1.? #=#=#=#=#=#
	/**
	 * Builds the Shadow DOM
	 */
	private build(
	): void {
		this.shadow.innerHTML	=	`
			<dialog part="dialog">
				<slot></slot>
			</dialog>
			<button part="toggle">
				<span class="bars"></span>
				<span class="sr-only">Toggle Menu</span>
			</button>
		`;
	}


	// #=#=#=#=#=# 1.? Connected Callback #=#=#=#=#=#
	/**
	 * Lifecycle Method: Connected Callback
	 */
	connectedCallback() {
		// #==== Set up Shadow Interaction ====#
		this.getElements();
		this.enqueueObserver();


		// #==== Event Listeners ====#
		this.subelements.button.addEventListener('click', this.toggleDialog.bind(this));
	}


	// #=#=#=#=#=# 1.? Get sub-elements #=#=#=#=#=#
	/**
	 * Stores the important subelements in the subelements object
	 */
	private getElements(
	): void {
		this.subelements = {
			button: this.shadow.querySelector('button') as HTMLButtonElement,
			dialog: this.shadow.querySelector('dialog') as HTMLDialogElement
		};
	}


	// #=#=#=#=#=# 1.? Enqueue Observer #=#=#=#=#=#
	private enqueueObserver() {
		// #==== Fetch Config ====#
		let breakpoints	= [
			{
				range: +(this.getAttribute('breakpoint')?? 768)
			}
		];


		// #==== Get Breakpoints ====#
		new BreakpointObserver(breakpoints, {
			element: this,
			onWithin: MenuElement.onWithin,
			onOutside: MenuElement.onOutside
		});
	}


	// #=#=#=#=#=# 1.? Event Handler: Outside Range #=#=#=#=#=#
	/**
	 * Sets the Element to a state for Desktop
	 */
	private static onOutside(
		this: BreakpointObserver
	): void {
		const element		=	this.getProperty('element') as MenuElement;

		element.setAttribute('layout', 'inline');
		element.open();
	}


	// #=#=#=#=#=# 1.? Event Handler: Within Range #=#=#=#=#=#
	/**
	 * Sets the Element to a state for Mobile
	 */
	private static onWithin(
		this: BreakpointObserver
	): void {
		const element		=	this.getProperty('element') as MenuElement;

		element.setAttribute('layout', 'overlay');
		element.close();
	}


	// #=#=#=#=#=# 1.? Event Handler: Button Interaction #=#=#=#=#=#
	/**
	 * Toggles the Dialogs visibility
	 */
	private toggleDialog(
	): void {
		// #==== Hide ====#
		if(this.subelements.dialog.open) {
			this.close();
		}


		// #==== Show ====#
		else {
			this.open();
		}
	}


	// #=#=#=#=#=# 1.? Event Open #=#=#=#=#=#
	/**
	 * Opens the Dialog
	 */
	public open() {
		this.subelements.dialog.show();
		this.setAttribute('open', '');
	}


	// #=#=#=#=#=# 1.? Event Close #=#=#=#=#=#
	/**
	 * Closes the Dialog
	 */
	public close() {
		this.subelements.dialog.close();
		this.removeAttribute('open');
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-menu', MenuElement);