/**
 * Inhaltsverzeichnis
 * 	1. Menu
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */


// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { CSSRuleSet } from '../../ts/class/style/exportlist';
import style from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. Class
// --------------------------------------------------
class SubMenuElement	extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#
	/** Observe Hover interaction */
	private static pointerObserver	=	window.matchMedia('(hover: hover)');


		// #==== Initialised ====#
	/** Shadow DOM */
	public shadowRoot: ShadowRoot	=	this.attachShadow({mode: 'open'});
	/**	Stylesheet */
	private stylesheet: CSSRuleSet	=	new CSSRuleSet(style);
	/** List of Subelements */
	private subElements: Record<string, HTMLElement>	=	{};


		// #==== Uninitialised ====#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor() {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadowRoot.adoptedStyleSheets	=	[this.stylesheet.getStylesheet()];


		// #==== Mehtods ====#
		this.build();
	}


	// #=#=#=#=#=# 1.3 Build #=#=#=#=#=#
	/**
	 * Builds the Shadow DOM
	 */
	private build(
	): void {
		this.shadowRoot.innerHTML	= `
			<button part="button">
				<slot name="label"></slot>
				<cvh-icon part="icon" character="f078"></cvh-icon>
			</button>
			<div part="container">
				<slot></slot>
			</div>
		`;
	}


	// #=#=#=#=#=# 1.4 Connected Callback #=#=#=#=#=#
	/**
	 * Lifecycle Method: Connected Callback
	 */
	connectedCallback(
	): void {
		// #==== Get Elements ====#
		this.subElements.button		=	this.shadowRoot.querySelector('button')		as HTMLElement;
		this.subElements.container	=	this.shadowRoot.querySelector('div')		as HTMLElement;
		this.subElements.icon		=	this.shadowRoot.querySelector('cvh-icon')	as HTMLElement;


		// #==== Events ====#
		this.callEvents();
	}


	// #=#=#=#=#=# 1.5 Events #=#=#=#=#=#
	/**
	 * Pairs events to the Submenu
	 */
	private callEvents(
	): void {
		// #==== Add a hover interaction ====#
		/* Use Media Observer */
		SubMenuElement.pointerObserver.addEventListener('change', this.toggleHover.bind(this));
		this.toggleHover();


		// #==== Add the Click interaction ====#
		this.subElements.button.addEventListener('click', this.toggle.bind(this));
	}


	// #=#=#=#=#=# 1.6 Toggle hover #=#=#=#=#=#
	/**
	 * Toggles the Hover interaction
	 */
	public toggleHover(
	): void {
		if (SubMenuElement.pointerObserver.matches) {
			this.addEventListener('mouseenter', this.open);
			this.addEventListener('mouseleave', this.close);
		} else {
			this.removeEventListener('mouseenter', this.open);
			this.removeEventListener('mouseleave', this.close);
		}
	}


	// #=#=#=#=#=# 1.7 Toggle Open #=#=#=#=#=#
	/**
	 * Toggles the Container
	 */
	public toggle(
	): void {
		if (this.hasAttribute('open')) {
			this.close();
		} else {
			this.open();
		}
	}


	// #=#=#=#=#=# 1.8 Event Open #=#=#=#=#=#
	/**
	 * Opens the Container
	 */
	public open(
	): void {
		this.setAttribute('open', '');
	}


	// #=#=#=#=#=# 1.9 Event Close #=#=#=#=#=#
	/**
	 * Closes the Container
	 */
	public close(
	): void {
		this.removeAttribute('open');
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-submenu', SubMenuElement);