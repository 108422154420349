export default class BreakpointObserver {
    breakpoints = [];
    queries = new Map();
    options = {};
    activeBreakpoint;
    activeQuery;
    constructor(breakpoints, options) {
        this.breakpoints = breakpoints ?? [];
        this.options = Object.assign(this.options, options);
        const init = this.getActiveMethod('onInit');
        if (init) {
            init();
        }
        this.setMediaQueries();
    }
    setMediaQueries() {
        for (const breakpoint of this.breakpoints) {
            this.observe(breakpoint);
        }
    }
    observe(breakpoint) {
        if (breakpoint.__active ?? false) {
            return;
        }
        const query = window.matchMedia(this.buildMediaQuery(breakpoint.range));
        const callback = this.handleChange.bind(this, breakpoint, query);
        this.queries.set(breakpoint.range, { event: query, callback: callback });
        query.addEventListener('change', callback);
        callback();
        breakpoint.__active = true;
    }
    unobserve(range) {
        const breakpoint = this.breakpoints.find((bp) => bp.range === range);
        if (!breakpoint?.__active) {
            return;
        }
        const query = this.queries.get(range);
        if (!query) {
            return;
        }
        query.event.removeEventListener('change', query.callback);
        this.queries.delete(range);
    }
    buildMediaQuery(range) {
        if (typeof range === 'number') {
            return `(width <= ${range}px)`;
        }
        return `(${range.min}px < width <= ${range.max}px)`;
    }
    handleChange(breakpoint, query) {
        this.activeBreakpoint = breakpoint;
        this.activeQuery = query;
        this.callActiveMethod('onChange');
        if (this.isEnter()) {
            this.callActiveMethod('onEnter');
        }
        if (this.isLeave()) {
            this.callActiveMethod('onLeave');
        }
        if (this.isWithin()) {
            this.callActiveMethod('onWithin');
        }
        else {
            this.callActiveMethod('onOutside');
        }
        if (this.isAbove()) {
            this.callActiveMethod('onAbove');
        }
        if (this.isBelow()) {
            this.callActiveMethod('onBelow');
        }
        this.activeBreakpoint.__state = this.getRangeState();
        delete this.activeBreakpoint;
        delete this.activeQuery;
    }
    callActiveMethod(name) {
        if (!this.activeBreakpoint) {
            throw new Error('No active breakpoint');
        }
        const method = this.getActiveMethod(name);
        if (!method) {
            return;
        }
        method(this.activeBreakpoint);
    }
    getActiveMethod(name) {
        const method = this.getProperty(name);
        if (!method) {
            return;
        }
        return method.bind(this);
    }
    getProperty(name) {
        if (!this.activeBreakpoint || !this.activeBreakpoint[name]) {
            return this.options[name];
        }
        return this.activeBreakpoint[name];
    }
    getRangeState() {
        if (this.isWithin()) {
            return 1;
        }
        if (this.isAbove()) {
            return 0;
        }
        if (this.isBelow()) {
            return -1;
        }
        throw new Error('Undefined range state');
    }
    addBreakpoint(breakpoint) {
        this.breakpoints.push(breakpoint);
        this.observe(breakpoint);
    }
    removeBreakpoint(range) {
        this.unobserve(range);
        this.breakpoints = this.breakpoints.filter((bp) => bp.range !== range);
    }
    isEnter() {
        if (!this.activeBreakpoint) {
            throw new Error('No active breakpoint');
        }
        return this.activeBreakpoint.__state !== 1 && this.isWithin();
    }
    isLeave() {
        if (!this.activeBreakpoint) {
            throw new Error('No active breakpoint');
        }
        return this.activeBreakpoint.__state === 1 && !this.isWithin();
    }
    isWithin() {
        if (!this.activeQuery) {
            throw new Error('No active breakpoint');
        }
        return this.activeQuery.matches;
    }
    isAbove() {
        if (!this.activeBreakpoint) {
            throw new Error('No active breakpoint');
        }
        if (typeof this.activeBreakpoint.range === 'number') {
            return window.innerWidth > this.activeBreakpoint.range;
        }
        return window.innerWidth > this.activeBreakpoint.range.max;
    }
    isBelow() {
        if (!this.activeBreakpoint) {
            throw new Error('No active breakpoint');
        }
        if (typeof this.activeBreakpoint.range === 'number') {
            return window.innerWidth <= this.activeBreakpoint.range;
        }
        return window.innerWidth <= this.activeBreakpoint.range.min;
    }
}
